import React from 'react'
import { Link, graphql } from 'gatsby'
import { Container, Hero, Layout } from '../components'
import { Text } from 'rebass'
import Helmet from 'react-helmet'
import config from '../utils/siteConfig'

const ThankyouPage = ({ data }) => {
  return (
    <Layout>
      <Helmet>
        <title>Kiitos ilmoittautumisesta – {config.siteTitle}</title>
        <meta name="description" content={config.siteDescription} />
        <meta
          property="og:title"
          content={`Kiitos ilmoittautumisesta – ${config.siteTitle}`}
        />
        <meta property="og:url" content={config.siteUrl} />
      </Helmet>

      <Hero
        title={data.contentfulPage.title}
        excerpt={data.contentfulPage.excerpt.excerpt}
        image={data.contentfulPage.image}
      />

      <Container py={[4, 5]} maxWidth="64em">
        <Text fontSize={[2, 3, null, 4]} textAlign="center">
          <Link to="/">Takaisin etusivulle &rarr;</Link>
        </Text>
      </Container>
    </Layout>
  )
}
export default ThankyouPage

export const query = graphql`
  query thanksQuery {
    contentfulPage(slug: { eq: "kiitos" }) {
      title
      excerpt {
        excerpt
      }
      image {
        fluid(maxWidth: 1440) {
          ...GatsbyContentfulFluid_tracedSVG
        }
      }
      body {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`
